import React,{useState, useRef, useEffect}  from "react";
// import PageView from 'components/PageView';
import {Select,Checkbox,FormGroup,FormControl,InputLabel,FormHelperText,OutlinedInput,Icon,Breadcrumbs,Typography,Link,FormControlLabel,MenuItem,Button} from '@material-ui/core';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import {useSelector} from 'react-redux';
import {validate,getCatSel,getOptionSel,getDataModal,reFreshModal,getLGA,deleteRec,checkExist,inArray
  ,getCOMM,getStateL,RenderList,RenderList2,sendToServer,changeTextHandler,getDATA,getCatSelgrp} from 'apis/myfunc';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Alert from '@material-ui/lab/Alert';
import {setClientLogIn}  from 'actions/profile';


export default ()=> {   
  const [formstate,setformstate] = useState({});
  const myprofile = useSelector(state => state.profile); 
  ///displmessage
  const[errorMessage,setSerrorMessage]= useState('');
  const[succMessage,setSsuccMessage]= useState('');
  const [error,setError] = useState({});
  const myArrdesc = ['Current Password','New Password','Confirm Password'];
  const myArr = ['opwd','pwd','cpwd'];

  const rendInp = myArr.map((val,index)=>{
      return (<GridItem key ={index} style={{marginTop: 5,width:'45em',marginLeft:5}} xs={12}  sm={6} md={6}>
                <FormGroup >
                  
                    <FormControl variant="outlined"  >                   
                      <InputLabel >{myArrdesc[index]}</InputLabel>
                      <OutlinedInput value={formstate[val]} placeholder={myArrdesc[index]}
                      ///later change val to db fields
                      type="password"
                      error={error[val]}
                      onChange={(e)=>{changeTextHandler(e.target.value,setformstate,formstate,val)}} /> 
                                            
                    </FormControl>
                </FormGroup>
            </GridItem>);
    });
    // const input_compul_array =['uname','vname','role'];
    const input_type = myArr.map(key=>{
        switch (key){
          
          default: return 'text';
        }
    });

    const subHandler = async (e)=>{
      e.preventDefault();
      setSsuccMessage('');setSerrorMessage('');
      if(validate(formstate,myArr,input_type,setSerrorMessage,setError)){        
        let arrSub ={};
        //formstate.role=='1'
        arrSub['createdby']= myprofile.id.id;
        arrSub['uname']= myprofile.id.uname;
        if(formstate.id){arrSub['updT']='1'; arrSub['upid']= formstate.id; }
        arrSub = {...formstate,...arrSub};
        const data = await sendToServer(arrSub,'chgpwd','profile',setSsuccMessage,setSerrorMessage);
        if(data == '2'){ 
          myprofile.id.chgpwd = 'Y';
          setClientLogIn(myprofile) ;
          sessionStorage.setItem("myprofile", JSON.stringify(myprofile));

        }
      }

    }

    const classE= ()=> !(errorMessage)||errorMessage===''? "" :(
      <Alert severity="error" style={{justifyContent:'center'}} >
          {null || errorMessage}
      </Alert>);
    const classS= ()=> !(succMessage)||succMessage===''? "" :(
      <Alert severity="success" style={{justifyContent:'center'}} >
          {null || succMessage}
      </Alert>);
      return (
        <>
          <GridContainer style={{marginTop:0,paddingTop:0}} >
            <GridItem xs={12} sm={12} md={12}> 
              <Card style={{paddingTop:0,marginTop:0}}>
                <CardHeader color="info" >
                  <CardIcon color="info" >
                      <Icon >Edit</Icon>
                  </CardIcon>
                  <Breadcrumbs aria-label="breadcrumb">
                      <Link color="inherit" href="/mypage" >
                          Home
                      </Link>
                      
                      <Typography color="textPrimary" style={{color:'#fff'}} >Change Password</Typography>
                      
                  </Breadcrumbs>
                      
                </CardHeader>
                <CardBody style={{paddingTop:0,marginTop:0}}>
                   <form method="post">
                  <GridContainer style={{display:'flex',textAlign:'center',paddingLeft:'10em',paddingRight:'10em'}}>
                    
                    <GridItem style={{marginTop: 5,width:'45em',marginLeft:5}} xs={12}  sm={12} md={12}>
                      {classE()}
                      {classS()}
                      {succMessage!==''? null : 
                        
                        (<>{rendInp}
                          <GridItem xs={12} sm={6} md={6}  style={{marginTop: 5,width:'45em',marginLeft:5}} > 
                          <FormGroup >
                            
                            <Button variant="contained" type="submit" onClick={subHandler} className="myprimary_bg" >
                              Update
                            </Button>
                          </FormGroup>
                          </GridItem>
                        </>)
                      }
                    </GridItem>
                    
                  </GridContainer>   
                  </form>   
                </CardBody>
                       
              </Card>
              
            </GridItem>
            
          </GridContainer>
        </>
  );
}