class Profile  {
    constructor(id,uname,vname,role_desc,role,chgpwd,geo_id,phnum,email,message,error_message){
        this.id = id;
        this.uname =uname;
        this.vname=vname;
        this.role_desc =role_desc;
        this.role=role;
        this.chgpwd = chgpwd;
        this.geo_id = geo_id;
        this.phnum =phnum;
        this.email=email;
        this.message = message;
        this.error_message = error_message;
        

    }
}
//[id,uname,vname,role,chgpwd,geo_id,phnum,email,message];
export default Profile;