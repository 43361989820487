import React, { useRef,useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Markup } from 'interweave';

export class ComponentToPrint extends React.PureComponent{
    render() {
    return (<table style={{border:2}}  >

        <thead style={{backgroundColor:'#abde79'}}>
            {
                this.props.columns.map((col,index)=>{
                    return <th key={index}>{col['Header']} </th>;
                })
            }
          
          
        </thead>
        <tbody>
            {/* {   this.props.rows.map( row =>{
                    let mrow = "";  
                    mrow ="<tr style='border:1'>";
                    this.props.columns.map((col,index)=>{
                        mrow +=  `<td key="${row.id}${index}" >${row[col['accessor']]} </td>`;
                    });
                    mrow +="</tr>";
                    return <Markup content={mrow} />;
                }) */
                this.props.rows.map( row =>{
                    // mrow ="<tr style='border:1'>";
                    let mrowc =[];
                    this.props.columns.map((col,index)=>{
                        mrowc.push(React.createElement('td',{},row[col['accessor']]));  
                        
                    });
                    let mrow = React.createElement('tr',{},mrowc);  
                    
                    return mrow;
                })    
                
            }
                
            
         
        </tbody>
      </table>);
    }

}
const Download = props =>{
    const [disp,setDisp]=useState(false);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        
    });
    const handlePrint_ = ()=>{
        // componentRef.current.print();
        document.querySelector('#prtdiv').display ='block';
        handlePrint();
        document.querySelector('#prtdiv').display ='none';
        

    }
        
    return (
        <>  
            <div id="prtdiv" style={{display:'none'}}>
                <ComponentToPrint  rows = {props.rows}  columns ={props.headCells} ref={componentRef} />

            </div>
            <button style={{marginLeft:'5px'}} className="btn btn-sm btn-outline-success" onClick={()=>handlePrint_()}><i className="fa fa-print"></i> PRINT</button>
        </>
    );
   
}

export default Download;