import { LOGIN,CLIENT_LOGIN,LOGOUT } from '../actions/profile';

export default (state =[], action) => {
    switch (action.type) {
      case LOGIN:
      case LOGOUT:
      case CLIENT_LOGIN:        
        return action.profile;
         
      default:
        return state;
    }
  };