import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

import AccessTime from "@material-ui/icons/AccessTime";
import {LockOpen}  from "@material-ui/icons";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import LoginBox from "components/Bodi.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";


import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  return (
    <div>
      
      <GridContainer>
        <GridItem xs={1} sm={1} md={3}>
        </GridItem>
        <GridItem xs={10} sm={10} md={6}>
          <Card >
            <CardHeader color="info" style={{textAlign:'center'}}>
                <LockOpen fontSize="large" />
            </CardHeader>
            <CardBody>
                {/* Where login username and password resides */}
              <LoginBox/>
            </CardBody>
            <CardFooter >
              
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={1} sm={1} md={3}>
        </GridItem>
      </GridContainer>
      
    </div>
  );
}
