import React , { useEffect,useState,useRef } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons

import Alert from '@material-ui/lab/Alert';

import {Select,Checkbox,FormGroup,FormControl,InputLabel,FormHelperText,OutlinedInput,FormControlLabel,MenuItem,Button} from '@material-ui/core';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import {Person}  from "@material-ui/icons";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Loader from 'components/Loader';

// import {Button,Form,Alert} from 'react-bootstrap';
import {useSelector} from 'react-redux';


import {Camera} from "react-camera-pro";

import {validate,getCatSel,getOptionSel,reFreshModal,deleteRec,getDataModal,RenderList,sendToServer,changeTextHandler,getDATA} from 'apis/myfunc';




import PageView from 'components/PageView';



import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function GroupFarmer() {
    const [visible,setVisible] = useState(false);

    const myprofile = useSelector(state => state.profile);
    const[sdata,setSdata]= useState([]);
    const[srefresh,setSrefresh]= useState('0');
    const msg =useRef(null);
    const [cnt,setCnt]=useState(0);
    
    const camera = useRef(null);
    
    const [numberOfCameras, setNumberOfCameras] = useState(0);
    
    
    const classes = useStyles();
    // useEffect( ()=>async()=> {
    //     if(srefresh === '0'){
    //       const data = await getDATA('getdata','gfarmer');
    //       setSdata(data);
    //       setSrefresh('1');
          
    //     }
    // }
      
    // ,[srefresh])

    useEffect(()=>{
        const getMyData= async()=>{
            if(srefresh === '0'){
                const data = await getDATA('getdata','farmer');
                setSdata(data);
                setSrefresh('1');
                
            }
        }

        getMyData()
    },[srefresh])
    const columns = [
        {
          title: "Name",
          field: "fname",
        },
        {
          title: "Group",
          field: "grpId",
        },
        {
          title: "Value Chain",
          field: "valuechain_id",
        }
        
      ];
  
      ///State/////////////////
        ///fns
     
        const [gvaluechain_id,gsetSvaluechain_id] = useState('');
        const [ggrpId,gsetSgrpId] = useState('');
        const [ggender,gsetSgender] = useState(''); //
        const [gageg,gsetSgageg] = useState('');
        //
  
        ///displmessage
        const[errorMessage,setSerrorMessage]= useState('');
        const[succMessage,setSsuccMessage]= useState('');
       
  
        ///////form state
        const [error,setError] = useState({});
        const [formstate,setFormstate] = useState({});
        
          
        const input_compul_array =['valuechain_id','grpId','fname','gender','ageg','fsize','pstatus','phnum','image'];
        const input_type = input_compul_array.map(key=>{
          switch (key){
            case 'email' : return 'email';
            case 'sregistered': return 'checkbox';
            case 'phnum': console.log('tel'); return 'tel';
            default: return 'text';
          }
      })
       ////EndState///////////////////////////
      
      
  
      
  
      
      const handleSubmit =async (event)=>{
        setVisible(true);

        event.preventDefault();
        if(validate(formstate,input_compul_array,input_type,setSerrorMessage,setError)){
          let arrSub ={};
          let myFormArr = event.target;
          for(let i=0;i< myFormArr.length-1;i++ ){
           
            arrSub[myFormArr[i].name]= myFormArr[i].value;
          }
          arrSub['image'] =formstate.image;
          arrSub['createdby']= myprofile.id.id;
          if(formstate.updT){arrSub['updT']='1';;arrSub['upid']= formstate.upid;};
          ////////////display content of a group for submission
          console.log(myFormArr);
          await sendToServer(arrSub,'ins','farmer',setSsuccMessage,setSerrorMessage);
          
          
          setSrefresh('0');
          //console.log( arrSub)
        }
        msg.current.focus();
        
        setVisible(false);
       
      }
  
      useEffect(()=>{
        setVisible(true);
        
        getCatSel(gsetSvaluechain_id,'valuechain_id');
        getOptionSel(gsetSgender,'Gender');
        getOptionSel(gsetSgageg,'Age');
        //console.log(camera.current)
        setVisible(false);
        
      },[]);
      useEffect(()=>{
        const getasyncRec= async()=>{
            setVisible(true);

            if(formstate.valuechain_id){ await getCatSel(gsetSgrpId,'grpId',formstate.valuechain_id);}
            setVisible(false);
            
        }
        getasyncRec()
    },[formstate.valuechain_id])

    const Col ='div';
    const classE= ()=> !(errorMessage)||errorMessage===''? "" :(
                            <Alert severity="error" style={{justifyContent:'center'}} >
                                {null || errorMessage}
                            </Alert>);
    const classS= ()=> !(succMessage)||succMessage===''? "" :(
                            <Alert severity="success" style={{justifyContent:'center'}} >
                                {null || succMessage}
                            </Alert>);
    
    let val;
    const content = (<>
            <Loader visible={visible} />
            <Card >
                <CardHeader color="info" style={{textAlign:'center',flex:1}}>
                    <Person fontSize="small" style={{marginBottom:0}} /><span style={{paddingTop:0}}>Farmers</span>
                </CardHeader>
                <CardBody>
                
                        <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={12}>
                            <div ref={msg} tabIndex="-1" >{classE()}{classS()}</div>
                        </GridItem>
                        <form onSubmit={handleSubmit} style={{display: succMessage===''? 'block': 'none'  }} method="post"  >
                        <GridContainer >
                            <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={6}>
                                <FormGroup >
                                    <Camera ref={camera} numberOfCamerasCallback={setNumberOfCameras} aspectRatio={16/9} style={{marginBottom:0}} />
                                    
                                    <Button variant="contained" type="button" hidden={numberOfCameras <= 1}  style={{left:'30%',width:'20vh',position:'relative',marginTop:0, display:numberOfCameras <= 1?'none' :'block'}} className="btn btn-warning"
                                    onClick={() => changeTextHandler(camera.current.takePhoto(),setFormstate,formstate,'image')}>Take photo</Button>
                                </FormGroup>
                            </GridItem>
                            <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={6}>
                                <FormGroup style={{textAlign:'center',height:'70%',paddingLeft:0,borderStyle: 'solid',borderWidth:'2px', borderRadius: 10,borderColor: error.image?'red':''}} >
                                    <img src={formstate.image}  name="image" alt='Farmer photo' height="200px" />
                                
                                </FormGroup>
                            </GridItem>
                        </GridContainer>
                        <GridContainer >
                        <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={6}>
                            <FormGroup >
                                <FormControl variant="outlined" className={classes.formControl} error={val= error.valuechain_id?'1':''} >
                                    <InputLabel id="valuechain_id-label">Value Chain</InputLabel>
                                    <Select 
                                    labelId="valuechain_id-label"
                                    
                                    onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,'valuechain_id');getCatSel(gsetSgrpId,'grpId',e.target.value);}} 
                                    id="valuechain_id"
                                    name="valuechain_id"  value={formstate.valuechain_id}
                                    label="Value Chain"
                                    >
                                        <MenuItem value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {RenderList(gvaluechain_id,'value_chain_desc')}
                                    </Select>
                                   
                                </FormControl>
                            
                            </FormGroup>
                        </GridItem> 
                        <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={6}>
                            <FormGroup >
                                <FormControl variant="outlined" className={classes.formControl}  error={val= error.grpId?'1':''} >
                                    <InputLabel id="grpId-label">Group/Enterprise</InputLabel>
                                    <Select 
                                    labelId="grpId-label"
                                    onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,'grpId')}}
                                
                                    id="grpId"
                                    name="grpId"  value={formstate.grpId}
                                    label="Group/Enterprise"
                                    >
                                        <MenuItem value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {RenderList(ggrpId,'gname')}
                                    </Select>
                                    
                                </FormControl>
                            
                            </FormGroup>
                        </GridItem>
                        </GridContainer>
                        <GridContainer >
                        <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={6}>
                            <FormGroup >
                                <FormControl variant="outlined" error={val= error.fname?'1':''} >
                                    <InputLabel htmlFor="fname">Name</InputLabel>
                                    <OutlinedInput  label="Name"  value={formstate.fname} 
                                    onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,'fname')}} id="fname" name="fname" />
                                    
                                </FormControl>
                            </FormGroup>
                        </GridItem>
                        <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={6}>
                            <FormGroup >
                                <FormControl variant="outlined" className={classes.formControl}  error={val= error.gender?'1':''} >
                                    <InputLabel id="gender-label">Gender</InputLabel>
                                    <Select 
                                    labelId="gender-label"
                                    onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,'gender')}} id="gender"
                                    name="gender" value={formstate.gender}
                                    label="Gender"
                                    >
                                        <MenuItem value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {RenderList(ggender,'opt_field')}
                                    </Select>
                                    
                                </FormControl>
                            
                            </FormGroup>
                        </GridItem>
                        </GridContainer>
                        <GridContainer >
                        <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={6}>
                            <FormGroup >
                                <FormControl variant="outlined" className={classes.formControl} error={val= error.ageg?'1':''} >
                                    <InputLabel id="ageg-label">Age Group</InputLabel>
                                    <Select 
                                    labelId="ageg-label"
                                    onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,'ageg')}} 
                                
                                    id="ageg"
                                    name="ageg" value={formstate.ageg} 
                                    label="Age Group"
                                    >
                                        <MenuItem value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {RenderList(gageg,'opt_field')}
                                    </Select>
                                   
                                </FormControl>
                            
                            </FormGroup>
                        </GridItem>
                        <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={6}>
                            <FormGroup >
                                <FormControl variant="outlined" error={val= error.fsize?'1':''} >
                                    <InputLabel htmlFor="fsize">Family Size</InputLabel>
                                    <OutlinedInput  label="Family Size" type="number"  value={formstate.fsize}
                                    onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,'fsize')}} id="fsize" name="fsize" />
                                    
                                </FormControl>
                            </FormGroup>
                        </GridItem>
                        </GridContainer>
                        <GridContainer >
                        <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={6}>
                            <FormGroup >
                                <FormControl variant="outlined" error={val= error.pstatus?'1':''} >
                                    <InputLabel htmlFor="pstatus">Position/Status in Group</InputLabel>
                                    <OutlinedInput  label="Position/Status in Group" type="text"  value={formstate.pstatus}
                                     onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,'pstatus')}} id="pstatus" 
                                     name="pstatus" />
                                    
                                </FormControl>
                            </FormGroup>
                        </GridItem>
                        <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={6}>
                            <FormGroup >
                                <FormControl variant="outlined" error={val= error.phnum?'1':''} >
                                    <InputLabel htmlFor="phnum">Phone Number</InputLabel>
                                    <OutlinedInput  label="Phone Number" type="number"   value={formstate.phnum}
                                     onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,'phnum')}} id="phnum" name="phnum" />
                                    
                                </FormControl>
                            </FormGroup>
                        </GridItem>
                        </GridContainer>
                       
                        <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={12} >
                            <FormGroup >
                                <Button variant="contained" type="submit" className="myprimary_bg" >
                                    {formstate.updT?'Update':'Submit'}
                                </Button>
                            </FormGroup>
                        </GridItem>
                    </form>
                    <hr className="col-sm-12"/>
                    
    
    
                    </CardBody>
                    <CardFooter >
                    
                    </CardFooter>
                </Card>
            
    </>);
  return (
    <div>
     
       
        <PageView myUrl="lfarmer" icon="person" nicon='Farmer' title="Farmers' Page " deleteRec={(id)=> deleteRec(id,'farmer',myprofile.id.id,setSrefresh)} getDataModal={(chk)=>{getDataModal(chk,'farmer',setFormstate,setSerrorMessage, setSsuccMessage,setError)}} reFreshModal={()=>{reFreshModal(setFormstate,setSerrorMessage, setSsuccMessage,setError)}}   size="60%" content={content} data={sdata} columns={columns} shtitle="Farmers" />

            
    </div>
  );
}
