/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import {LockOpen ,Language}  from "@material-ui/icons";

// core components/views for Admin layout
import Loginpg from "views/Login/Login";
import GroupFarmerPg from "views/GroupFarmer/GroupFarmer"
import SaverFormPg from "views/SaverForm/SaverForm"
import TrainingPg from "views/Training/Training"
import HformPg from "views/Hform/Hform"
import RuralinfPg1 from "views/Ruralinf/Ruralinf1"
import RuralinfPg2 from "views/Ruralinf/Ruralinf2"
import FinputPg from "views/Finput/Finput"
import FlabourPg from "views/Flabour/Flabour"
import FriPg from "views/Fri/Fri"
import RiplPg from "views/Ripl/Ripl"
import PlffPg from "views/Plff/Plff"
import IprffpPg from "views/Iprffp/Iprffp"
import PecsrPg from "views/Pecsr/Pecsr"
import AgrprPg from "views/Agrpr/Agrpr"
import PaaqPg from "views/Paaq/Paaq"
import ChangePwdPg from "views/ChangePwd/ChangePwd"
import IncubatePg from "views/Incubate/Incubate"
import UsersPg from "views/Users/Users"

import FarmerPg from "views/Farmer/Farmer";
import LformPg from "views/Lform/Lform";
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";

const dashboardRoutes = [
  {
    path: "",
    name: "LIFE-ND",
    rtlName: "",
    icon: LockOpen,
    component: Loginpg,
    layout: "/login"
  },
  
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/mypage"
  },
  {
    path: "/gfarmer",
    name: "Membership of Groups/Producers' Organization Supported",
    rtlName: "",
    icon: '',
    component: GroupFarmerPg,
    layout: "/mypage"
  },
  {
    path: "/savfrm",
    name: "Enterprise Savings Form",
    rtlName: "",
    icon: '',
    component: SaverFormPg,
    layout: "/mypage"
  }
  ,
  {
    path: "/chgpwd",
    name: "Change Password",
    rtlName: "",
    icon: '',
    component: ChangePwdPg,
    layout: "/mypage"
  },
  {
    path: "/hform",
    name: "Support to Improved Household Nutrition",
    rtlName: "",
    icon: '',
    component: HformPg,
    layout: "/mypage"
  },
  {
    path: "/finput",
    name: "Farm Input Related Expenditure Record (Crops/Farm)",
    rtlName: "",
    icon: '',
    component: FinputPg,
    layout: "/mypage"
  },
  {
    path: "/flabour",
    name: "FARM LABOUR RELATED EXPENDITURE (Crops/Farm)",
    rtlName: "",
    icon: '',
    component: FlabourPg,
    layout: "/mypage"
  },
  {
    path: "/fri",
    name: "FARM PRODUCE & INCOME: CROPS, LIVESTOCK OR FISH",
    rtlName: "",
    icon: '',
    component: FriPg,
    layout: "/mypage"
  },
  {
    path: "/ripl",
    name: "Record of Input Purchase Livestock",
    rtlName: "",
    icon: '',
    component: RiplPg,
    layout: "/mypage"
  },
  {
    path: "/plff",
    name: "Purchase of Livestock Feed and Feeding",
    rtlName: "",
    icon: '',
    component: PlffPg,
    layout: "/mypage"
  },
  {
    path: "/iprffp",
    name: "Input Purchase Record Fish Farm/Pond",
    rtlName: "",
    icon: '',
    component: IprffpPg,
    layout: "/mypage"
  },
  {
    path: "/pecsr",
    name: "Postharvest/Enterprise Cost and Sales Record",
    rtlName: "",
    icon: '',
    component: PecsrPg,
    layout: "/mypage"
  },
  {
    path: "/agrpr",
    name: "Agroprocessing",
    rtlName: "",
    icon: '',
    component: AgrprPg,
    layout: "/mypage"
  },
  {
    path: "/paaq",
    name: "PRODUCTIVE ASSET ACQUISITION",
    rtlName: "",
    icon: '',
    component: PaaqPg,
    layout: "/mypage"
  },
  {
    path: "/ruralinf1",
    name: "RURAL INFR. (MARKET FACILITIES/SHADING STRUCTURES, PROCESSING FACILITIES, STORAGE AND PRODUCTION FACILITIES)",
    rtlName: "",
    icon: '',
    component: RuralinfPg1,
    layout: "/mypage"
  },
  {
    path: "/ruralinf2",
    name: "RURAL INFR. (WASTE DISPOSAL SYSTEMS, SANITATION SYSTEMS, WATER SYSTEMS)",
    rtlName: "",
    icon: '',
    component: RuralinfPg2,
    layout: "/mypage"
  },
  {
    path: "/training",
    name: "Attendance Sheet for All Trainings",
    rtlName: "",
    icon: '',
    component: TrainingPg,
    layout: "/mypage"
  },
  {
    path: "/lfarmer",
    name: "Farmers",
    rtlName: "",
    icon: '',
    component: FarmerPg,
    layout: "/mypage"
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "",
    icon: '',
    component: UsersPg,
    layout: "/mypage"
  },
  {
    path: "/lform",
    name: "Loan Form",
    rtlName: "",
    icon: '',
    component: LformPg,
    layout: "/mypage"
  },
  {
    path: "/incubate",
    name: "Incubator/Incubatee Physical/Financial Monitoring ",
    rtlName: "",
    icon: '',
    component: IncubatePg,
    layout: "/mypage"
  }
  ,
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: UserProfile,
  //   layout: "/mypage"
  // },
  {
    path: "/table",
    name: "Table List",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: TableList,
    layout: "/mypage"
  },
  {
    path: "/typography",
    name: "Typography",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: Typography,
    layout: "/mypage"
  },
  {
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/mypage"
  },
  {
    path: "/maps",
    name: "Maps",
    rtlName: "خرائط",
    icon: LocationOn,
    component: Maps,
    layout: "/mypage"
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/mypage"
  },
  {
    path: "/rtl-page",
    name: "RTL Support",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: RTLPage,
    layout: "/rtl"
  },
  {
    path: "/upgrade-to-pro",
    name: "Upgrade To PRO",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: UpgradeToPro,
    layout: "/mypage"
  }
];

export default dashboardRoutes;
