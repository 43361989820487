import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import {setClientLogIn,setLogIn,logOut}  from 'actions/profile';
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

///redux

import {useSelector,useDispatch} from 'react-redux';
import { useHistory,useParams} from 'react-router-dom'

import routes from "routes.js";


import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";



let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/mypage") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/mypage" to="/mypage/dashboard" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const myprofile = useSelector(state => state.profile);
  const isLoggedIn = useSelector(state => state.isLoggedIn );
  const dispatch = useDispatch();
  const history = useHistory();
  const rurl ='/mypage'; 
  let { rid } = useParams();
  //console.log(myprofile)
  const { role } = myprofile.id || {} ;
  let  checkLogin = true;
  
  if(isLoggedIn.length ===0){
      if(sessionStorage.getItem("isLoggedIn")=== 'true'){
          //console.log('yes')
         
          dispatch(setClientLogIn(JSON.parse(sessionStorage.getItem("myprofile"))));
          //console.log(JSON.parse(sessionStorage.getItem("myprofile")));
          dispatch(setLogIn(true));
          // console.log('is it here')
          // console.log(myprofile)
      }else{
          history.push('/');
          checkLogin = false;
      }
  }

  ///session expiry..
  if(checkLogin){
    let mytime = Date.now();
    if(sessionStorage.getItem("mysess")){
      if( Number(mytime) - Number(sessionStorage.getItem("mysess"))  >= 1000*60*15 ){ //15 minutes
        dispatch(logOut());
        dispatch(setLogIn(false));
        history.push('');
        //history.push('/');
        checkLogin = false;
      }
    }
    
    sessionStorage.setItem('mysess', mytime);

  }

  // console.log('role')
  // console.log(myprofile )
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if(checkLogin){
      if (navigator.platform.indexOf("Win") > -1) {
        ps = new PerfectScrollbar(mainPanel.current, {
          suppressScrollX: true,
          suppressScrollY: false
        });
        document.body.style.overflow = "hidden";
      }
      window.addEventListener("resize", resizeFunction);
      // Specify how to clean up after this effect:
      return function cleanup() {
        if (navigator.platform.indexOf("Win") > -1) {
          ps.destroy();
        }
        window.removeEventListener("resize", resizeFunction);
      };
    }
    
  }, [mainPanel]);
  return ( <>{checkLogin ? <div className={classes.wrapper}>
      <Sidebar
        role={role}
        logoText={"LIFEND Data Collect"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
       
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        <Footer /> 
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
      </div>
    </div> : null }</>
    
  );
}
