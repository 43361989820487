import { ISLOGGEDIN } from '../actions/profile';

export default (state = [], action) => {
    switch (action.type) {
      case ISLOGGEDIN:
        
        return action.signedIn;
      
      default:
        return state;
    }
  };