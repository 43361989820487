import React,{useState, useRef, useEffect, useCallback, useMemo } from 'react';
import ReactToPrint from 'react-to-print';
// import {Link} from 'react-router-dom';
// import Table from './Table';
// import Table from './RTable';
import Table from './RTable1';
// import Table from './MUITable';
// import Modal from 'Modal' ;
import {useSelector} from 'react-redux';
import Alert from '@material-ui/lab/Alert';


import {getModalContent} from 'apis/myfunc';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { HighlightOff, VerticalAlignBottomOutlined } from '@material-ui/icons';
import Loader from '../components/Loader';
import { makeStyles } from '@material-ui/core/styles';

import {validate,getCatSel,getOptionSel,getDataModal,reFreshModal,getLGA,deleteRec,checkExist,inArray
  ,getCOMM,getStateL,RenderList,RenderList2,sendToServer,changeTextHandler,getDATA,getCatSelgrp} from 'apis/myfunc';
import {Select,Checkbox,FormGroup,FormControl,InputLabel,FormHelperText,OutlinedInput,FormControlLabel,MenuItem,Button} from '@material-ui/core';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import {Icon } from '@material-ui/core';
import Interweave from 'interweave';




const Addform = (props)=>{
  
  const [formstate,setformstate] = useState({});
  // const [popstate, setpopstate] = useState({});
  const myprofile = useSelector(state => state.profile);
  const [mrole,setmrole] = useState('');
  const [mgeo_id,setmgeo_id] = useState('');
  const [mlga_id,setmlga_id] = useState('');

  ///displmessage
  const[errorMessage,setSerrorMessage]= useState('');
  const[succMessage,setSsuccMessage]= useState('');
  const [error,setError] = useState({});

  const myArrdesc = ['Username','Name','Role','State','Lga'];
  const myArr = ['uname','vname','role','geo_id','lga_id'];
  const myArr2 = ['Username','Name','role_desc','area_name','subarea_name'];
  const myArr3 = ['','',mrole,mgeo_id,mlga_id];
 
  useEffect(()=>{ 
    getCatSel(setmrole,'Roles');
    getCatSel(setmgeo_id,'State');
    if(formstate.geo_id !== '') { getCatSel(setmlga_id,'Lga',mgeo_id); } else { setmlga_id('');}

    ///get values of rowid 
    const fn = async()=>{
      if(props.rowid && typeof props.rowid ==='string'){
        const data = await getDATA('getdata','profile',props.rowid);
        setformstate(data[0]);
        console.log(data[0])
      }
    }
    fn();
    
  },[]);

  useEffect(()=>{ if(formstate.geo_id !== '') { getCatSel(setmlga_id,'Lga',formstate.geo_id);} else { setmlga_id('');} },[formstate.geo_id]);

  
 
  

  
  const showRoleArr = ['1','5','7'];
  const rendInp = myArr.map((val,index)=>{
                  //chkchages
                  if(props.rowid && typeof props.rowid ==='string' && val==='uname') 
                  {return (<label style={{fontSize:'1.2em'}}>{formstate.uname}</label>)}

                  if(formstate.role && !inArray(formstate.role,['3'])  && val==='lga_id') {if(formstate.lga_id!==null) {changeTextHandler(null,setformstate,formstate,'lga_id');} return null;}
                  
                  if(formstate.role && inArray(formstate.role,showRoleArr)  && val==='geo_id') {if(formstate.geo_id!==null) {changeTextHandler(null,setformstate,formstate,'geo_id');} return null;}
                  return (<GridItem key={index} style={{marginTop: 5,width:'100%',marginLeft:5}} xs={12}  sm={12} md={12}>
                              <FormGroup >
                                
                                  <FormControl variant="outlined"  >
                                      
                                      { val==="role" || val==="geo_id" || val==="lga_id" ? (<>
                                        <InputLabel >{myArrdesc[index]}</InputLabel>
                                        <Select  value={String(formstate[val])}
                                            onChange={(e)=>{changeTextHandler(e.target.value,setformstate,formstate,val); 
                                              // if(val==="Roles"){chkChanges();}
                                            }} 
                                            error={error[val]}
                                           
                                          >
                                            <MenuItem value="">
                                                <em>Choose</em>
                                            </MenuItem>
                                            
                                            {RenderList(myArr3[index],myArr2[index])}
                                            
                                        </Select></>)
                                        :
                                          <OutlinedInput value={formstate[val]} placeholder={myArrdesc[index]}
                                          ///later change val to db fields
                                          error={error[val]}
                                          onChange={(e)=>{changeTextHandler(e.target.value,setformstate,formstate,val)}} /> 

                                      }
                                      
                                                           
                                  </FormControl>
                              </FormGroup>
                          </GridItem>
                          );
                });

  const input_compul_array =['uname','vname','role'];
  const input_type = input_compul_array.map(key=>{
      switch (key){
        case 'email' : return 'email';
        case 'registered': return 'checkbox';
        case 'phnum':  return 'tel';
        default: return 'text';
      }
  })

  const subHandler =async (e)=>{
    e.preventDefault();
    console.log(formstate);
    // console.log('error')
    // console.log(error)
    setSsuccMessage('');setSerrorMessage('');
    if(validate(formstate,input_compul_array,input_type,setSerrorMessage,setError)){
      let arrSub ={};
      //formstate.role=='1'
      if(inArray(formstate.role,showRoleArr)) { arrSub['geo_id']='';} else if(!formstate.geo_id || formstate.geo_id =='' ) { setSerrorMessage("Invalid State Fields"); return;}
      if(!inArray(formstate.role,['3'])) { arrSub['lga_id']='';} else if(!formstate.lga_id || formstate.lga_id =='' ) { setSerrorMessage("Invalid Lga Fields"); return;}
      arrSub['createdby']= myprofile.id.id;
      if(formstate.id){arrSub['updT']='1'; arrSub['upid']= formstate.id; }
      arrSub = {...formstate,...arrSub};
      const data = await sendToServer(arrSub,'ins','profile',setSsuccMessage,setSerrorMessage);
      if(data==1 || data ==2){
        props.refreshme();
      }
    }
  }

  const classE= ()=> !(errorMessage)||errorMessage===''? "" :(
    <Alert severity="error" style={{justifyContent:'center'}} >
        {null || errorMessage}
    </Alert>);
  const classS= ()=> !(succMessage)||succMessage===''? "" :(
    <Alert severity="success" style={{justifyContent:'center'}} >
        {null || succMessage}
    </Alert>);

  return (
    <div style={{textAlign:'center', paddingLeft:30,paddingRight:20}}>
      
 <form method="post">
      <GridContainer >
       
          <div><h3>{props.rowid && typeof props.rowid ==='string'? 'Edit Record' :'Add New Record'}</h3></div>
          {classE()}
          {classS()}
          <GridItem style={{marginTop: 5,width:'100%',marginLeft:5}} xs={12}  sm={12} md={12}>
          
            {succMessage!==''? null : 
              
              (<>{rendInp}
              
                <FormGroup style={{marginTop:5}} >
                  
                  <Button variant="contained" type="submit" onClick={subHandler} className="myprimary_bg" >
                  {props.rowid && typeof props.rowid ==='string'? 'Update' :'Submit'}
                  </Button>
                </FormGroup>
              </>)
            }
          </GridItem>
          
          
          
        
      </GridContainer>
      </form>
    </div>
  )
}

const Resform = (props)=>{
  
  const [formstate,setformstate] = useState({});
  // const [popstate, setpopstate] = useState({});
  const myprofile = useSelector(state => state.profile);
  
  ///displmessage
  const[errorMessage,setSerrorMessage]= useState('');
  const[succMessage,setSsuccMessage]= useState('');
  const [error,setError] = useState({});

  
 
  useEffect(()=>{ 
    
    ///get values of rowid 
    const fn = async()=>{
      if(props.rowid && typeof props.rowid ==='string'){
        const data = await getDATA('getdata','profile',props.rowid);
        setformstate(data[0]);
        console.log(data[0])
      }
    }
    fn();
    
  },[]);

  const subHandler =async () =>{
    // alert()
    // e.preventDefault();
    console.log(formstate);
    // console.log('error')
    // console.log(error)
    setSsuccMessage('');setSerrorMessage('');
    
    let arrSub ={};
    //formstate.role=='1'
    // if(inArray(formstate.role,showRoleArr)) { arrSub['geo_id']='';} else if(!formstate.geo_id || formstate.geo_id =='' ) { setSerrorMessage("Invalid State Fields"); return;}
    arrSub['createdby']= myprofile.id.id;
    if(formstate.id){arrSub['updT']='1'; arrSub['upid']= formstate.id; }
    arrSub = {...formstate,...arrSub};
    const data = await sendToServer(arrSub,'respwd','profile',setSsuccMessage,setSerrorMessage);
    if(data==1 || data ==2){
      alert("New Password is 123456");
    }
    
  }

  const rendInp = (<>
        <GridItem  style={{marginTop: 5,width:'45em',marginLeft:5,fontSize:'2em'}} xs={12}  sm={12} md={12}>
               Do you want to reset the password of {formstate.vname} ?            
        </GridItem>
        <GridItem  style={{marginTop: 5,width:'45em',marginLeft:5,fontSize:'2em'}} xs={12}  sm={12} md={12}>
              
              <Button variant="contained" type="button" style={{float:'left'}} onClick={subHandler} color="secondary" >
              YES
              </Button>           
              <Button variant="contained" type="button" style={{float:'right'}} onClick={props.handleClose} color="info" >
              NO
              </Button>  
                   
        </GridItem>
        </>)

  
 

  

  

  const classE= ()=> !(errorMessage)||errorMessage===''? "" :(
    <Alert severity="error" style={{justifyContent:'center'}} >
        {null || errorMessage}
    </Alert>);
  const classS= ()=> !(succMessage)||succMessage===''? "" :(
    <Alert severity="success" style={{justifyContent:'center'}} >
        {null || succMessage}
    </Alert>);

  return (
    <div style={{textAlign:'center', paddingLeft:30,paddingRight:20}}>
      
      <div><h3>Reset Password</h3></div>
      <GridContainer >
        {/* <form method="post"> */}
          
          {classE()}
          {classS()}
          <GridItem style={{marginTop: 5,width:'45em',marginLeft:5}} xs={12}  sm={12} md={12}>
          
            {succMessage!==''? null : 
              
              (<>{rendInp}
              
                
              </>)
            }
          </GridItem>
          
          
          
        {/* </form> */}
      </GridContainer>
    </div>
  )
}


const ShowTable=(props)=>{
  const componentRef = useRef();
  const [visible,setVisible] = useState(false);
  const [chkmodal,setChkmodal] = useState(false);
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'block',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'auto',
    //   maxHeight:'80%',
      position:'relative',
      marginTop:5,
      
      width:props.size,
       minWidth:'50%',//to be worked on at css level
    //    marginLeft: window.matchMedia("(max-width: 400px)").matches?'':'45vh',
    //    top:'90%',
    //   left:'30%',
      maxHeight: '95%',
      
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    
  }));
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modalwidth,setModalwidth]= useState('');
  const [content,setContent]=useState('');
  const [rowidtab,setrowidtab]=useState('')
  const [typ,setTyp]=useState('')
  let MyComp = null;

  switch(typ){
    case 'res': MyComp = Resform; break;
    default : MyComp = Addform;
  }

  const handleOpen = async (val='',typ='') => {
      setVisible(true);
    // //const data="Hello";
    
      ///Users
      if(props.table === 'profile'){
        setModalwidth('30%');
        setContent(false);
        setrowidtab(val);
        setTyp(typ);
        // if(typ===''){
          
        // }

      }else{
        const {data} = await getModalContent(props.table,val);

        setModalwidth('');

        setContent(data);

      }
    //setChkmodal(true)
    //alert('open'+val)
    setTimeout(() => setOpen(true), 500)
    setVisible(false);        
  };

  const refreshme =()=>{props.refreshme();}
  const handleClose = () => {
          
      setOpen(false);
  };
    
    return (<>
            <Loader visible={visible} />
{/* Add print page... */}
    <div className="table-responsive" id="myTab">
        <div style={{display: props.table==='profile'?'block':'none',position:'relative',float:'right',margin:'10px' }}>
          <button className="btn btn-outline-success" onClick={handleOpen}>Add New</button>
        </div>
        <Table shtitle={props.shtitle} data={props.data} table={props.table} handleOpen={handleOpen} columns={props.columns}   />
    </div>
    <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          disableBackdropClick
          style={{marginLeft:modalwidth}}
          BackdropProps={{
          timeout: 500,
          }}
      >   
       
          <Fade in={open}>
          <div className={classes.paper} >
              <Icon onClick={handleClose} className="success" style={{float:'right',fontSize:'3em',zIndex:'20000',color:'#55ae2d',cursor:'pointer', position:'absolute',top:0,right:0}}>
                  <HighlightOff  />
              </Icon>
              {/* <h2 id="transition-modal-title">{props.shtitle}</h2> */}
              <div id="transition-modal-description">
                {/* <ReactToPrint
                  trigger={() => <button>Print this out!</button>}
                  content={() => componentRef.current}
                /> */}
                <div id="printdiv" style={{display: props.table==='profile'?'none': 'block'}}>
                  <button onClick={() => {document.querySelector('#printdiv').style.display='none';
                      document.querySelector('#thead').style.color='#55ae2d';
                      window.print();
                      document.querySelector('#thead').style.color='#fff';
                     //document.querySelector('#printArea').print();
                //      var mywindow = window.open('', 'PRINT', 'height=400,width=600');

                //      mywindow.document.write('<html><head><title>' + document.title  + '</title>');
                //      mywindow.document.write('<link rel="stylesheet" href="/bootstrap.min.css" integrity="sha384-TX8t27EcRE3e/ihU7zmQxVncDAy5uIKz4rEkgIXeMed4M0jlfIDPvg6uqKI2xXr2" crossorigin="anonymous">');

                //      mywindow.document.write('</head><body >');
   
                //      mywindow.document.write('<h1>' + document.title  + '</h1>');
                //      mywindow.document.write(document.getElementById('printArea').innerHTML);
                //      ;
                //  setTimeout(() => mywindow.document.write('</body></html>'), 500)
                //     // mywindow.document.close(); // necessary for IE >= 10
                //      ; // necessary for IE >= 10*/
                //     mywindow.focus()
                    
                 
                //      mywindow.print()
                      //mywindow.close();
                 
                     //return true;

                     ////////////
                      document.querySelector('#printdiv').style.display='block';
                      }} className="btn btn-sm btn-danger">PRINT</button>
                </div>
                <div id="printArea" style={{overflow:'auto'}} >
                  { content ? <Interweave content={content}   /> : <MyComp rowid={rowidtab} refreshme ={refreshme} handleClose={handleClose} />}
                  

                </div>

                
              </div>
          </div>
          </Fade>
      </Modal>
    {/* <ModalDisp /> */}
    {/* {chkmodal? <Modal />: null}  */}
      {/* <Modal content={content} show={open}/> */}
     
       
    </>);
}

export default ShowTable;