import api from '../apis/api';
import Profile from '../models/profile';

export const LOGIN ='LOGIN';
export const LOGOUT ='LOGOUT';
export const CLIENT_LOGIN ='CLIENT_LOGIN';
export const ISLOGGEDIN ='ISLOGGEDIN';

export const login = formData => async dispatch => {
  let response
  try {
    response= await api.post('/loginw',formData);
    //console.log(response)
    const profile = new Profile(response.data);
    dispatch({ type: LOGIN, profile });
  } 
  catch(err){
    // alert(err)
    alert('Something went wrong!\nSeems you are Offline.\n'+err)
  }
  // console.log()
  // if(response && response.data){
    
  // }else{
  //   throw new Error('Something went wrong!');
  // }
  
  
};

export const logOut = ()  => {
  sessionStorage.setItem('myprofile', '');
  
  return {
    type: LOGOUT,  profile:{}
  }
  
};
export const setLogIn = ( value= null) =>{
  sessionStorage.setItem('isLoggedIn', value);
  return {
    type: ISLOGGEDIN, signedIn: value 
  }
};

export const setClientLogIn = (profile) =>{
  // console.log('profile')
  // console.log(profile)
  // sessionStorage.setItem('myprofile', JSON.parse(profile));

  return {
    type: CLIENT_LOGIN,  profile
  }
};
