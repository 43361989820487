import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import RTLNavbarLinks from "./RTLNavbarLinks.js";
import Button from "components/CustomButtons/Button.js";
import logo from "assets/img/reactlogo.png";

import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import {useSelector} from 'react-redux';

const useStyles = makeStyles(styles);

export default function Header(props) {
  const myprofile = useSelector(state => state.profile);
  const classes = useStyles();
  function makeBrand() {
    var name;
    props.routes.map(prop => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = props.rtlActive ? prop.rtlName : prop.name;
        if(prop.layout === '/login') name = <img src= {logo} style={{maxHeight:'6em',position:'absolute', top:0,margin:0}} />;
      }
      
      return null;
    });
    
    //if(props.routes.layout === '/login') return ()
    return name;
  }
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
    <AppBar className={classes.appBar + appBarClasses} style={{ width:'100%'}} >
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <div color="transparent" href="#" style={{overflow:'auto',display:'inline-block',wordWrap:'break-word',overflowWrap:'break-word'}} className={classes.title} >
            {makeBrand()}
          </div>
        </div>
        <Hidden smDown implementation="css">
          {/* {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />} */}
          {myprofile.id ? <AdminNavbarLinks /> : null}
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
