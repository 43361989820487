import React,{useState,useEffect} from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import {useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';


import { bugs, website, server } from "variables/general.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {getDATA,getCount} from '../../apis/myfunc';


const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const[group,setSgroup]= useState('');
  const[pgroup,setSpgroup]= useState('');//producer,processor ,marketer
  const[prgroup,setSprgroup]= useState('');
  const[mkgroup,setSmkgroup]= useState('');
  const[cuser,setScuser]= useState('');
  const history = useHistory();
  
  
  const[farmerAttr0,setSarmerAttr0]= useState('');
  const[farmerAttr1,setSarmerAttr1]= useState('');
  const[farmerAttr2,setSarmerAttr2]= useState('');
  const[farmerAttr3,setSarmerAttr3]= useState('');
  const[farmerAttr4,setSarmerAttr4]= useState('');
  const[farmerAttr5,setSarmerAttr5]= useState('');
  
  const[fincubAttr0,setSincubAttr0]= useState('');
  const[fincubAttr1,setSincubAttr1]= useState('');
  const[fincubAttr2,setSincubAttr2]= useState('');
  const[fincubAttr3,setSincubAttr3]= useState('');
  const[fincubAttr4,setSincubAttr4]= useState('');
  const[fincubAttr5,setSincubAttr5]= useState('');
  const[fincubAttr6,setSincubAttr6]= useState('');
  const[fincubAttr7,setSincubAttr7]= useState('');

  const myprofile = useSelector(state => state.profile); 

  const getMyData= async()=>{
      // setVisible(true);
        //history.push('');
                
      if(Object.keys(myprofile).length === 0 ){ history.push(''); return;}
      if(myprofile.id.chgpwd === 'N' ){ history.push('/mypage/chgpwd');alert("You need to change your password"); return;}
      let mygeo_id =myprofile.id.geo_id  
        let resp = await getCount(mygeo_id,'gfarmer',"");
      console.log("resp");
      console.log(resp);
      setSgroup(resp.data);
      resp = await getCount(mygeo_id,'gfarmer',0,'gfarmer***grpType***grpId***1000');
      // resp = await getCount(mygeo_id,'farmer',"grpId in (SELECT id FROM gfarmer  WHERE grpType = '1000')",'gfarmer***grpType***grpId***1000');
      setSpgroup(resp.data);
      resp = await getCount(mygeo_id,'gfarmer',1,'gfarmer***grpType***grpId***1000');
      // resp = await getCount(mygeo_id,'farmer',"grpId in (SELECT id FROM gfarmer  WHERE grpType = '1001')",'gfarmer***grpType***grpId***1001'); 
      setSprgroup(resp.data);
      resp = await getCount(mygeo_id,'gfarmer',2,'gfarmer***grpType***grpId***1000');
      // resp = await getCount(mygeo_id,'farmer',"grpId in (SELECT id FROM gfarmer  WHERE grpType = '1002')",'gfarmer***grpType***grpId***1002');
      setSmkgroup(resp.data);

      resp = await getCount(mygeo_id,'groupget','');
       setScuser(resp.data[0]);
       setSarmerAttr0(resp.data[1]);
       setSarmerAttr1(resp.data[2]);
       setSarmerAttr2(resp.data[3]);
       setSarmerAttr3(resp.data[4]);
       setSarmerAttr4(resp.data[5]);
       setSarmerAttr5(resp.data[6]);
       
       setSincubAttr0(resp.data[7]);       
       setSincubAttr1(resp.data[8]);
       setSincubAttr2(resp.data[9]);
       setSincubAttr3(resp.data[10]);
       setSincubAttr4(resp.data[11]);
       setSincubAttr5(resp.data[12]);
       setSincubAttr6(resp.data[13]);
       setSincubAttr7(resp.data[14]);
     /* resp = await getCount(mygeo_id,'profile',"");      
     

      resp = await getCount(mygeo_id,'farmer',0);      
      
      resp = await getCount(mygeo_id,'farmer',1);      
      
      resp = await getCount(mygeo_id,'farmer',2);      
      
      resp = await getCount(mygeo_id,'farmer',3);      
      
      resp = await getCount(mygeo_id,'farmer',4);      
      
      resp = await getCount(mygeo_id,'farmer',5);      
      


      resp = await getCount(mygeo_id,'incubate',0);      
      
      resp = await getCount(mygeo_id,'incubate',1);      
      
      resp = await getCount(mygeo_id,'incubate',2);      
      
      resp = await getCount(mygeo_id,'incubate',3);      
      
      resp = await getCount(mygeo_id,'incubate',4);      
      
      resp = await getCount(mygeo_id,'incubate',5);      
      
      resp = await getCount(mygeo_id,'incubate',6);      
      
      resp = await getCount(mygeo_id,'incubate',7);      
       */
      // setVisible(false);        
  }  

  useEffect(()=>{
    const fn =async()=>{
        // setVisible(true);
        await getMyData() 
        // setVisible(false);
    }
    fn()    
  },[])
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>people</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Groups</p>
              <h3 className={classes.cardTitle}>
                {group}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              {/* <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  Get more space
                </a>
              </div> */}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Producers</p>
              <h3 className={classes.cardTitle}>
                {pgroup}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              {/* <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  Get more space
                </a>
              </div> */}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Processors</p>
              <h3 className={classes.cardTitle}>
                {prgroup}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              {/* <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  Get more space
                </a>
              </div> */}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Marketers</p>
              <h3 className={classes.cardTitle}>
                {mkgroup}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              {/* <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  Get more space
                </a>
              </div> */}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Users</p>
              <h3 className={classes.cardTitle}>
                {cuser}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Male Adult [MEMBERS]</p>
              <h3 className={classes.cardTitle}>
                {farmerAttr0}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Male Youth [MEMBERS]</p>
              <h3 className={classes.cardTitle}>
                {farmerAttr1}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Female Adult [MEMBERS]</p>
              <h3 className={classes.cardTitle}>
                {farmerAttr2}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              
            </CardFooter>
          </Card>
        </GridItem> <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Female Youth [MEMBERS]</p>
              <h3 className={classes.cardTitle}>
                {farmerAttr3}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              
            </CardFooter>
          </Card>
        </GridItem> <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Female Headed Households [MEMBERS]</p>
              <h3 className={classes.cardTitle}>
                {farmerAttr4}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              
            </CardFooter>
          </Card>
        </GridItem> <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Persons With Disabilities [MEMBERS]</p>
              <h3 className={classes.cardTitle}>
                {farmerAttr5}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              
            </CardFooter>
          </Card>
        </GridItem> 
        
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>INCUBATORS</p>
              <h3 className={classes.cardTitle}>
                {fincubAttr6}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>INCUBATEES</p>
              <h3 className={classes.cardTitle}>
                {fincubAttr7}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Male Adult [INCUBATEES]</p>
              <h3 className={classes.cardTitle}>
                {fincubAttr0}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Male Youth [INCUBATEES]</p>
              <h3 className={classes.cardTitle}>
                {fincubAttr1}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Female Adult [INCUBATEES]</p>
              <h3 className={classes.cardTitle}>
                {fincubAttr2}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              
            </CardFooter>
          </Card>
        </GridItem> <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Female Youth [INCUBATEES]</p>
              <h3 className={classes.cardTitle}>
                {fincubAttr3}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              
            </CardFooter>
          </Card>
        </GridItem> <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Female Headed Households [INCUBATEES]</p>
              <h3 className={classes.cardTitle}>
                {fincubAttr4}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              
            </CardFooter>
          </Card>
        </GridItem> <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Persons With Disabilities [INCUBATEES]</p>
              <h3 className={classes.cardTitle}>
                {fincubAttr5}
                {/* <small>GB</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Daily Sales</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Email Subscriptions</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Completed Tasks</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title="Tasks:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Bugs",
                tabIcon: BugReport,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0, 3]}
                    tasksIndexes={[0, 1, 2, 3]}
                    tasks={bugs}
                  />
                )
              },
              {
                tabName: "Website",
                tabIcon: Code,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0]}
                    tasksIndexes={[0, 1]}
                    tasks={website}
                  />
                )
              },
              {
                tabName: "Server",
                tabIcon: Cloud,
                tabContent: (
                  <Tasks
                    checkedIndexes={[1]}
                    tasksIndexes={[0, 1, 2]}
                    tasks={server}
                  />
                )
              }
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Employees Stats</h4>
              <p className={classes.cardCategoryWhite}>
                New employees on 15th September, 2016
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={["ID", "Name", "Salary", "Country"]}
                tableData={[
                  ["1", "Dakota Rice", "$36,738", "Niger"],
                  ["2", "Minerva Hooper", "$23,789", "Curaçao"],
                  ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
                  ["4", "Philip Chaney", "$38,735", "Korea, South"]
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
     */}
    </div>
  );
}
