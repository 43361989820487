import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
// import './App.css';


// class App extends React.Component {
const App = props => {
  
    // let people= [
    //     { name: "Keanu Reeves", profession: "Actor" },
    //     { name: "Lionel Messi", profession: "Football Player" },
    //     { name: "Cristiano Ronaldo", profession: "Football Player" },
    //     { name: "Jack Nicklaus", profession: "Golf Player" },
    // ];
    
    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = props.filename;
        // const headers = [["NAME", "PROFESSION"]];
        let headers =[];
        props.headCells.forEach((col)=>{
            headers.push(col['Header']);
        });
        headers = [headers];
        // const data = people.map(elt=> [elt.name, elt.profession]);
        // const data = props.rows;
        const data = props.rows.map(elt=>{
            let arr =[];
            props.headCells.map((col)=>{
                arr.push(elt[col['accessor']])
            });
            return arr ; 
        } );

        let content = {
        headStyles: { fillColor: [171, 222, 121] },
        startY: 50,
        head: headers,
        body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(title.trim()+".pdf")
    }

  
    return (
      <>
        <button style={{marginLeft:'5px'}}  className="btn btn-sm btn-outline-success" onClick={() => exportPDF()}><i className="fa fa-file-pdf-o"></i> PDF</button>
      </>
    );
 
}

export default App;
