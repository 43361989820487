import React from 'react';
import Loader from "react-loader-spinner";

const Loaders=({visible})=>{
    return (
        <div style={{position:'fixed',top:0,
        left:0,
        width: '100%',
        height: '100%',
        background: 'black',
        opacity: '.6',
        zIndex:'10000000', display:visible?'block':'none'}} >
            <div style={{position: 'fixed',left:'30%',top:'30%',flexDirection:'row',display:'flex'}}>
                <Loader
                    type="Bars"
                    // color="#00BFFF"
                    color="#53ac57"
                    height={100}
                    width={100}
                    visible ={visible}
                    // timeout={3000} //3 secs
                /><span style={{color: 'white',paddingTop:30,fontSize:80,}}>Please Wait...</span>
            </div>
        
        </div>
    )
}

export default Loaders