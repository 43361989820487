import React,{useState, useEffect, useRef} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {login,setLogIn}  from '../actions/profile';
import { useHistory } from 'react-router-dom';
import {Button,FormControl,InputLabel,FormHelperText,FormGroup,OutlinedInput } from '@material-ui/core';
import {chkError} from 'apis/myfunc.js';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Alert from '@material-ui/lab/Alert';
import Loader from '../components/Loader';



 const Bodi= ()=>{
    const [uname,setUname] = useState('');
    const [pwd,setPwd] = useState('');
    const unameRef = useRef();
    const pwdRef = useRef();
    const [error,setError] = useState({});
    const [displa,setDispla] = useState('none');
    const dispatch = useDispatch();
    const history = useHistory();
    const [visible,setVisible] = useState(false);
    const myprofile = useSelector(state => state.profile);
    
    let mytime = Date.now();
    
    sessionStorage.setItem('mysess', mytime);

    

    const checkLogin = async()=>{
        //alert('I am here')
        const myDataObj = { uname : uname, pwd: pwd};
        const formData = new FormData();
        for (let key in myDataObj) {
            formData.append(key, myDataObj[key])
        }
        //const response= await api.post('/login',formData);
        setVisible(true);
        await dispatch(login(formData));
        setVisible(false);

        //let {data}=response
        //console.log(response);
        
        
    }
    useEffect(()=>{
        if(myprofile.id){
            if(myprofile.id.message ==='ERROR'){
                let obj ={};
                obj.error_message = myprofile.id.error_message;
                setError(obj);setDispla('')
            }else{
                setLogIn(true);
                sessionStorage.setItem("isLoggedIn", true);
                sessionStorage.setItem("myprofile", JSON.stringify(myprofile));
                history.push('/mypage');
            }
        }   
        
    },[myprofile,dispatch])
        
    
    const onSubmitHandler= (e) =>{
        e.preventDefault();
        let obj ={};setDispla('none')
    
       if(pwd.trim() === ''){
            obj.pwd = 'Please type in your password';
            //pwdRef.current.style.borderColor ='red'
       }
       if(uname.trim() === ''){
            obj.uname = 'Please type in your username';
            //unameRef.current.style.borderColor ='red'
        }
        setError(obj);
        const objArr = Object.keys(obj);

        if(objArr.length===0)checkLogin();
        //alertMe()

    }
    
    
    //const val =()=>{ return chkError(error.uname);}
let val;
  return (
    <>
       
         <Loader visible={visible} />
        <GridContainer style={{ alignItems: 'center', justifyContent:'center',flex:1, }}    >
            <GridItem xs={12} sm={12} md={12}>
                
                        {/* //show error */}
                        <Alert severity="error" style={{display: displa,justifyContent:'center'}} >
                            {null || error.error_message}
                        </Alert>
                        
                
                <form method="post">
                    <FormGroup  style={{paddingBottom:3, marginTop:5}}>
                        <FormControl variant="outlined" error={val= error.uname?'1':''} >
                            <InputLabel htmlFor="uname">Enter Username</InputLabel>
                            <OutlinedInput id="uname"  label="Username" onChange={(e)=>{setUname(e.target.value);}} />
                            <FormHelperText>{null || error.uname}</FormHelperText>
                        </FormControl>
                    </FormGroup>
                    
                    <FormGroup>
                        <FormControl variant="outlined" error={val= error.pwd?'1':''} >
                            <InputLabel htmlFor="pwd">Enter Password</InputLabel>
                            <OutlinedInput id="pwd" type="password"  label="Password" onChange={(e)=>{setPwd(e.target.value);}} />
                            <FormHelperText>{null || error.pwd}</FormHelperText>
                        </FormControl>
                    </FormGroup>

                    
                    <Button variant="contained" fullWidth={true} className="myprimary_bg" onClick={onSubmitHandler} type="submit" >
                        Log In
                    </Button>
                </form>
                            
                       
        
            </GridItem>
        </GridContainer>
        
    </>
    );
}
export default Bodi;