import { combineReducers } from 'redux';

import profileReducer from './profileReducer';
import signedInReducer from './signedInReducer';

export default combineReducers({
  profile: profileReducer,
  isLoggedIn :signedInReducer, 
 
});
