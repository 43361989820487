import React,{useState,useEffect} from 'react';
// import {Link} from 'react-router-dom';
// import MaterialTable  from "material-table";
// import  MTableToolbar from "material-table";
// import ModalN from  '../Modal';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { InputLabel,FormGroup,FormControl,Breadcrumbs,Typography,Link,Icon,Button,Select,MenuItem, } from '@material-ui/core';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import {useSelector} from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import ShowTable from './ShowTable';
import Loader from '../components/Loader';
import {validate,getCatSel,getOptionSel,getDataModal,reFreshModal,getLGA,deleteRec,checkExist
    ,getCOMM,GetStateL,RenderList,sendToServer,changeTextHandler,getDATA} from 'apis/myfunc';
import { ContactlessTwoTone } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';


const CheckLogPageView = props => {
    const myprofile = useSelector(state => state.profile);
    const history = useHistory();
     
    const chgPwd= ()=>{
        history.push('/mypage/chgpwd');alert("You need to change your password");
    }
    // alert (myprofile)
    return(<>{
        Object.keys(myprofile).length === 0? history.push('') :(
            <>
                {
                    myprofile.id.chgpwd === 'N'? chgPwd() : <PageView {...props} />
                } 
            </>
        )
        
    } </>)
    
    

}


const PageView = props => {
    //const ml =0 || props.ml || '45vh';
   
    const myprofile = useSelector(state => state.profile);
    
    

    const geoid = myprofile.id.geo_id;
    const lgaid = myprofile.id.lga_id;

    const ml ='0';
    const [visible,setVisible] = useState(false);

    const useStyles = makeStyles((theme) => ({
        modal: {
          display: 'block',
          alignItems: 'center',
          justifyContent: 'center',
          overflow:'auto',
        //   maxHeight:'80%',
          position:'relative',
          marginTop:5,
          
          width:props.size,
          minWidth:'80%',//to be worked on at css level
        //    marginLeft: window.matchMedia("(max-width: 400px)").matches?'':'45vh',
        //    top:'90%',
        //   left:'30%',
          maxHeight: '95%',
          
        },
        paper: {
          backgroundColor: theme.palette.background.paper,
          border: '2px solid #000',
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
        },
        
    }));
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [modalwidth,setModalwidth]= useState('');
    const [showmystate,setShowmystate] = useState('');
    const [getlga,setGetlga] = useState('');
    const [getcomm,setGetcomm] = useState('');
    const [smggrp,setMggrp] = useState('');


    const [showtab,setShowtab]=useState(false);
    const [showopt,setShowopt]=useState('');


    const [error,setError] = useState({});
    const [mstate,setMstate] = useState('');
    const [mlga,setMlga] = useState('');
    const [mcommunity,setMcommunity] = useState('');
    const [mgrp,setMgrp] = useState('');

    // const handleOpen = async (val='') => {
    //     setVisible(true);

    //     if(val!==''){
    //         setStitle(`Edit ${props.shtitle} Record`)
    //         await props.getDataModal(val);
    //     }else{
    //         setStitle(`Add New ${props.shtitle}`)
    //         await props.reFreshModal();
    //     }
    //     ;
    //     setTimeout(() => setOpen(true), 500)
    //     setVisible(false);

        
    // };

    
    // const [show,setShow]= useState(false);
    const [stitle,setStitle]= useState('');
    const[sdata,setSdata]= useState([]);

    
   
    useEffect(()=>{
        
        setVisible(true);
        if(props.table === 'profile' ){
            setShowopt('1');
            getMyData(myprofile.id.id);    
        }else if(props.table === 'training' || props.table === 'profile' ){
            setShowopt('1');
            getMyData();    
        }else if(props.table === 'hform' || props.table === 'ruralinf1' || props.table === 'ruralinf2' || props.table === 'incubate'){
            setShowopt('2');
            GetStateL(setShowmystate,geoid);
               
        }else if(props.table === 'finput' || props.table === 'flabour' || props.table === 'fri'
            || props.table === 'ripl' || props.table === 'plff' || props.table === 'iprffp'|| props.table === 'pecsr'
            || props.table === 'agrpr' || props.table === 'paaq'  ){
            setShowopt('3');
            GetStateL(setShowmystate,geoid);
               
        }else{
            GetStateL(setShowmystate,geoid);
        }     
        setVisible(false)
    },[])
    useEffect(()=>{
        const getasyncRec= async()=>{
            setVisible(true);

            if(mstate){ await getLGA(mstate,setGetlga,setGetcomm,lgaid)}
            //if(formstate_1.lga){ await getCOMM(formstate_1.lga,setGetcomm)}
            //console.log('lgastate')
            setMggrp('');
            setVisible(false);
            setShowtab(false)


        }
        getasyncRec()
    },[mstate])
    useEffect(()=>{
        const getasyncRec= async()=>{
            setVisible(true);

            //if(formstate_1.state){ await getLGA(formstate_1.state,setGetlga,setGetcomm)}
            if(mlga){ await getCOMM(mlga,setGetcomm)}
            //console.log('lgastate')
            if(props.table === 'hform' || props.table === 'ruralinf1' || props.table === 'ruralinf2' || props.table === 'incubate' ){
                setShowtab(true)
                await getMyData(mlga);
            }else{
                setShowtab(false)
            }
            setMggrp('');
            setVisible(false);

        }
        getasyncRec()
    },[mlga])
    useEffect(()=>{
        const getasyncRec= async()=>{
            setVisible(true);
            if(props.table === 'finput' || props.table === 'flabour'  || props.table === 'fri'
                || props.table === 'ripl' || props.table === 'plff' || props.table === 'iprffp'|| props.table === 'pecsr'
                || props.table === 'agrpr' || props.table === 'paaq'){
                setShowtab(false);
                await getCatSel(setMggrp,'grpId_oth',mcommunity);
            }else
            if(mcommunity){
                setShowtab(true); 
                await getMyData(mcommunity);// true
            }else{
                setShowtab(false)
            }
            
            setVisible(false);

        }
        getasyncRec()
    },[mcommunity])

    useEffect(()=>{
        const getasyncRec= async()=>{
            setVisible(true);
            if(mcommunity){
                setShowtab(true);
                await getMyData(mgrp);// true
            }else{
                setShowtab(false);
            }
            setVisible(false);

        }
        getasyncRec()
    },[mgrp])
    
    const getMyData= async(id='')=>{
        
        const data = await getDATA('getdata',props.myUrl,'',id);
        //data.table = props.table;
        
        setSdata(data);
        if(props.table==='training' || props.table==='profile' ){
            setShowtab(true);
        }
           
    }
    let columns
    switch (props.table){
            case 'profile': columns = [
                {
                title: "User Name",
                Header: "User Name",                           
                accessor: "uname",
                sortable: true,
                },
                {
                    title: "Name",
                    Header: "Name",                           
                    sortable: true,
                    accessor: "vname",
                },
                {
                    title: "Role",
                    Header: "Role",                           
                    sortable: true,
                    accessor: "role_desc",
                },
                {
                    title: "Location",
                    Header: "Location",                           
                    sortable: true,
                    accessor: "location",
                }
            ];
            break;
            case 'loanform':columns = [
                    {
                    title: "Group Name",
                    Header: "Group Name",                           
                    sortable: true,
                    accessor: "grpId",
                    },
                    {
                    Header: "Loan Code",
                    title: "Loan Code",
                    field: "loanCode",
                    sortable: true,
                    accessor: "loanCode",
                    },
                    {
                    Header: "Source Loan",
                    title: "Source Loan",
                    accessor: "sourceLoan",
                    sortable: true,
                    field: "sourceLoan",
                    }
                    ,
                    {
                    Header: "Term",                   
                    sortable: true,
                    accessor: "term_desc",                   
                    }
                    
                ];
                break;
            case 'training':columns = [
                {
                
                Header: "Date of Training",                           
                sortable: true,
                accessor: "tdate_desc",
                },
                {
                Header: "Component/Sub-Component",               
                sortable: true,
                accessor: "comp_subcomp",
                },
                {
                Header: "Training Topic",
                sortable: true,
                accessor: "train_topic",
                
                }
                ,
                {
                Header: "Place",                   
                sortable: true,
                accessor: "place",                   
                }
                
            ];
            break;
        case 'ruralinf1':
        case 'ruralinf2':
        case 'hform':
            columns = [
            {
            
                Header: "Community",                           
                sortable: true,
                accessor: "community_Desc",
            }
        ];
        break;
        case 'incubate':columns = [
            {
            
                Header: "Community",                           
                sortable: true,
                accessor: "community",
            },{
            
                Header: "Reporting Month",                           
                sortable: true,
                accessor: "repp",
            }
        ];
        break;
        case 'plff':
        case 'iprffp':
        case 'pecsr':
        case 'agrpr':
        case 'paaq':
        case 'ripl':
        case 'fri':
        case 'flabour':
        case 'finput':columns = [
            {            
                Header: "Picture",                           
                sortable: true,
                accessor: "pic",
            },
            {            
                Header: "Name",                           
                sortable: true,
                accessor: "fname",
            },
            
        ];
        break;
        default: columns = [
            {
              title: "Group Name",
              Header: "Group Name",
              field: "gname",
                sortable: true,
                accessor: "gname",
            },
            {
              Header: "Group Type",
              title: "Group Type",
                sortable: true,
                field: "grpType",
              accessor: "grpType",
            },
            {
              Header: "Group Code",
              title: "Group Code",
                sortable: true,
                accessor: "grpCode",
              field: "grpCode",
            }
            
          ];
    }
    

    
    return (
        <>
            <Loader visible={visible} />

           
            <GridContainer style={{marginTop:0,paddingTop:0}}>
                {/* <GridItem xs={12} sm={12} md={12}>
                   
                    
                </GridItem> */}
            
                
                <GridItem xs={12} sm={12} md={12}>  
                    <Card style={{paddingTop:0,marginTop:0}}>
                        <CardHeader color="info" >
                            <CardIcon color="info" >
                                <Icon >{props.icon}</Icon>
                            </CardIcon>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link color="inherit" href="/mypage" >
                                    Home
                                </Link>
                                
                                <Typography color="textPrimary" style={{color:'#fff'}} >{props.nicon}</Typography>
                                
                            </Breadcrumbs>
                                <div >
                                {/* {props.addshow?null:(<Button variant="contained" className="myprimary_bg_inv" style={{float:'right'}}
                                        onClick={()=>handleOpen()}
                                    //  onclick="add('User')" 
                                        type="button">
                                        <i className="fa fa-plus-circle "></i> &nbsp;&nbsp;ADD
                                </Button>)} */}
                                
                                </div>
                        </CardHeader>
                        <CardBody style={{paddingTop:0,marginTop:0}}>
                            <GridContainer style={{background:'#efebd8',display:'flex',textAlign:'center',paddingLeft:'10em',paddingRight:'10em'}}>
                                
                                {showopt===''?<><GridItem xs={4} sm={4} md={4} style={{display:'flex'}}>
                                    <FormGroup  style={{width:'100%',marginTop:10,marginBottom:5,}}>
                                        <FormControl variant="outlined" 
                                        //  error={val= error.grpType?'1':''}
                                        
                                        >
                                            <InputLabel id="state-label">State</InputLabel>
                                  
                                            <Select 
                                                labelId="state-label"
                                                onChange={(e)=>{ setMstate(e.target.value);
                                                // getLGA(e.target.value,setGetlga,setGetcomm)
                                                }} 
                                                value={mstate}
                                                label="State"
                                                >
                                                    <MenuItem value=" ">
                                                        <em>Choose</em>
                                                    </MenuItem>
                                                    {RenderList(showmystate,'area_name')}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </GridItem>
                                <GridItem xs={4} sm={4} md={4} style={{display:'flex'}}>
                                    <FormGroup  style={{width:'100%',marginTop:10,marginBottom:5,}}>
                                        <FormControl variant="outlined" 
                                        //  error={val= error.grpType?'1':''}
                                        
                                        >
                                            <InputLabel id="lga-label">LGA</InputLabel>
                                            <Select 
                                                labelId="lga-label"
                                                onChange={(e)=>{setMlga(e.target.value);
                                                // getCOMM(e.target.value,setGetcomm)
                                                }} 
                                                 value={mlga}
                                                label="LGA"
                                            >
                                                <MenuItem value="">
                                                    <em>Choose</em>
                                                </MenuItem>
                                                {RenderList(getlga,'subarea_name')}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                   
                                </GridItem>
                                <GridItem xs={4} sm={4} md={4} style={{display:'flex'}}>
                                <FormGroup  style={{width:'100%',marginTop:10,marginBottom:5,}}>
                                        <FormControl variant="outlined" 
                                        //  error={val= error.grpType?'1':''}
                                        
                                        >
                                            <InputLabel id="community-label">Community</InputLabel>
                                            <Select 
                                                labelId="community-label"
                                                onChange={(e)=>{setMcommunity(e.target.value);}}                                                                                           
                                                value={mcommunity}
                                                label="Community"
                                            >
                                                <MenuItem value="">
                                                    <em>Choose</em>
                                                </MenuItem>
                                                {RenderList(getcomm,'community_name')}
                                            </Select>
                                        </FormControl>
                                    </FormGroup> 
                                </GridItem>
                                    </> : null}

                                {showopt==='2'?<><GridItem xs={6} sm={6} md={6} style={{display:'flex'}}>
                                            <FormGroup  style={{width:'100%',marginTop:10,marginBottom:5,}}>
                                                <FormControl variant="outlined" 
                                                //  error={val= error.grpType?'1':''}
                                                
                                                >
                                                    <InputLabel id="state-label">State</InputLabel>
                                        
                                                    <Select 
                                                        labelId="state-label"
                                                        onChange={(e)=>{ setMstate(e.target.value);
                                                        // getLGA(e.target.value,setGetlga,setGetcomm)
                                                        }} 
                                                        value={mstate}
                                                        label="State"
                                                        >
                                                            <MenuItem value=" ">
                                                                <em>Choose</em>
                                                            </MenuItem>
                                                            {RenderList(showmystate,'area_name')}
                                                    </Select>
                                                </FormControl>
                                            </FormGroup>
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={6} style={{display:'flex'}}>
                                            <FormGroup  style={{width:'100%',marginTop:10,marginBottom:5,}}>
                                                <FormControl variant="outlined" 
                                                //  error={val= error.grpType?'1':''}
                                                
                                                >
                                                    <InputLabel id="lga-label">LGA</InputLabel>
                                                    <Select 
                                                        labelId="lga-label"
                                                        onChange={(e)=>{setMlga(e.target.value);
                                                        // getCOMM(e.target.value,setGetcomm)
                                                        }} 
                                                        value={mlga}
                                                        label="LGA"
                                                    >
                                                        <MenuItem value="">
                                                            <em>Choose</em>
                                                        </MenuItem>
                                                        {RenderList(getlga,'subarea_name')}
                                                    </Select>
                                                </FormControl>
                                            </FormGroup>
                                        
                                        </GridItem>
                                    </> : null}
                                {showopt==='3'?<><GridItem xs={3} sm={3} md={3} style={{display:'flex'}}>
                                        <FormGroup  style={{width:'100%',marginTop:10,marginBottom:5,}}>
                                            <FormControl variant="outlined" 
                                            //  error={val= error.grpType?'1':''}
                                            
                                            >
                                                <InputLabel id="state-label">State</InputLabel>
                                    
                                                <Select 
                                                    labelId="state-label"
                                                    onChange={(e)=>{ setMstate(e.target.value);
                                                    // getLGA(e.target.value,setGetlga,setGetcomm)
                                                    }} 
                                                    value={mstate}
                                                    label="State"
                                                    >
                                                        <MenuItem value=" ">
                                                            <em>Choose</em>
                                                        </MenuItem>
                                                        {RenderList(showmystate,'area_name')}
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                    </GridItem>
                                    <GridItem xs={3} sm={3} md={3} style={{display:'flex'}}>
                                        <FormGroup  style={{width:'100%',marginTop:10,marginBottom:5,}}>
                                            <FormControl variant="outlined" 
                                            //  error={val= error.grpType?'1':''}
                                            
                                            >
                                                <InputLabel id="lga-label">LGA</InputLabel>
                                                <Select 
                                                    labelId="lga-label"
                                                    onChange={(e)=>{setMlga(e.target.value);
                                                    // getCOMM(e.target.value,setGetcomm)
                                                    }} 
                                                    value={mlga}
                                                    label="LGA"
                                                >
                                                    <MenuItem value="">
                                                        <em>Choose</em>
                                                    </MenuItem>
                                                    {RenderList(getlga,'subarea_name')}
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                    
                                    </GridItem>
                                    <GridItem xs={3} sm={3} md={3} style={{display:'flex'}}>
                                        <FormGroup  style={{width:'100%',marginTop:10,marginBottom:5,}}>
                                            <FormControl variant="outlined" 
                                            //  error={val= error.grpType?'1':''}
                                            
                                            >
                                                <InputLabel id="community-label">Community</InputLabel>
                                                <Select 
                                                    labelId="community-label"
                                                    onChange={(e)=>{setMcommunity(e.target.value);}}                                                                                           
                                                    value={mcommunity}
                                                    label="Community"
                                                >
                                                    <MenuItem value="">
                                                        <em>Choose</em>
                                                    </MenuItem>
                                                    {RenderList(getcomm,'community_name')}
                                                </Select>
                                            </FormControl>
                                        </FormGroup> 
                                    </GridItem>
                                    <GridItem xs={3} sm={3} md={3} style={{display:'flex'}}>
                                        <FormGroup  style={{width:'100%',marginTop:10,marginBottom:5,}}>
                                            <FormControl variant="outlined" 
                                            //  error={val= error.grpType?'1':''}
                                            
                                            >
                                                <InputLabel id="group-label">Group</InputLabel>
                                                <Select 
                                                    labelId="group-label"
                                                    onChange={(e)=>{setMgrp(e.target.value);}}                                                                                           
                                                    value={mgrp}
                                                    label="Group"
                                                >
                                                    <MenuItem value="">
                                                        <em>Choose</em>
                                                    </MenuItem>
                                                    {RenderList(smggrp,'gname')}
                                                </Select>
                                            </FormControl>
                                        </FormGroup> 
                                    </GridItem>
                                </> : null}
                            </GridContainer>
                            {console.log(showtab)}
                            {showtab?
                                <ShowTable shtitle={props.shtitle} data={sdata}
                                    columns={columns}
                                    deleteRec={props.deleteRec}
                                    table={props.myUrl}
                                    size={props.table==='profile'?'50px':''}
                                    refreshme={()=>getMyData(myprofile.id.id)}
                            />
                            :''}
                            
                           
                        </CardBody>
                    </Card>
                 </GridItem>
                
            </GridContainer>
        </>
    );
}

export default CheckLogPageView;