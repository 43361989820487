import React from 'react';
import api from './api';
// import MenuItem from '@material-ui/core/MenuItem';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import {Select,Checkbox,TextField,FormGroup,FormControl,InputLabel,FormHelperText,OutlinedInput,FormControlLabel,MenuItem,Button} from '@material-ui/core';
import CurrencyInput from './CurrencyInput'
// import TextField from 'material-ui/core/TextField';




export const getModalContent =async(tab,id)=>{
  try{
    const data = await api.get(`getcontent?tab=${tab}&id=${id}`);
    return data;
  }
  catch(err){
    // alert(err)
    alert('Something went wrong!\nSeems you are Offline.\n'+err)
  }
}
export const reFreshModal= (setformstate,errorM,succM,setErr)=>{
  setformstate({});
  errorM('');succM('');setErr({});
  // arrstate.forEach((item)=>{
  //   item('');
  // })
}

export const deleteRec=async (id,tab,user,setSrefresh)=>{
  try{
    await api.get(`del?tab=${tab}&id=${id}&user=${user}`);
    alert ('Deleted Successfully')
    setSrefresh('0')
  }
  catch(err){
    // alert(err)
    alert('Something went wrong!\nSeems you are Offline.\n'+err)
  }
  
}

export const checkExist =async (name,val,tab)=>{
  try{
    const response =await api.get(`check?tab=${tab}&val=${val}&name=${name}`);
    return response
  }
  catch(err){
    // alert(err)
    alert('Something went wrong!\nSeems you are Offline.\n'+err)
  }
  
}

export const getDataModal= async(chk,tab,setformstate,errorM,succM,setErr,myurl='getdata')=>{
  const getmydata= await getDATA(myurl,tab,chk);
  const obj = {updT: "1",upid: chk}
  await setformstate({...getmydata[0],...obj});
  errorM('');succM('');setErr({});
  console.log('get data for opening')
  console.log(getmydata)
  
}

export const chkError=(val)=>{
  console.log(val)
  if(val && val===''){
      return ;
  }
  return '1';
}

export const getLGA = async(val,setGetlga,setGetcomm,lga_id='')=>{
  try{
      const response= await api.get('/getlga',{
          params: {
            id:val,
            lga_id
          }
        });
      const {data} = response;
      setGetlga(data);
      setGetcomm('');
      //console.log(data);
        // if() 
      //   alert ("state changed")
      //   alert (document.querySelector("#gname").value)
      return 
    }
    catch(err){
      // alert(err)
      alert('Something went wrong!\nSeems you are Offline.\n'+err)
    }
    
  }
    
export const getCOMM = async(val,setGetcomm)=>{
    try{
      const response= await api.get('/getcomm',{
          params: {
            id:val,
          }
        });
      const {data} = response;
      setGetcomm(data);
      //console.log(data);
        // if() 
      //   alert ("state changed")
      //   alert (document.querySelector("#gname").value)
      return 
    }
    catch(err){
      // alert(err)
      alert('Something went wrong!\nSeems you are Offline.\n'+err)
    }
    
  }
  export const inArray=(needle, haystack)=> {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] == needle) return true;
    }
    return false;
  }
 export const getStateL = async(setShowmystate)=>{
   try{
    const response= await api.get('/getstate');
    const {data} = response;
    //setShowmystate(data);
    //console.log(showmystate+'hdhd')
    setShowmystate(data)
    //console.log(data);
    //console.log(showmystate+'hdhd')

    return
   }
   catch(err){
    // alert(err)
    alert('Something went wrong!\nSeems you are Offline.\n'+err)
    }
     
 }

 export const getCount =async (mygeo_id,tab,where,wh2='')=>{ 
    try{
      const response =await api.get(`count?tab=${tab}&where=${where}&mygeo_id=${mygeo_id}`);
      return response
    }
    catch(err){
      // alert(err)
      alert('Something went wrong!\nSeems you are Offline.\n'+err)
    } 
  }

 export const GetStateL = async(setShowmystate,geoid='')=>{
  
  try{
   const response= await api.get(`/getstate?id=${geoid}`);
   const {data} = response;
   //setShowmystate(data);
   //console.log(showmystate+'hdhd')
   setShowmystate(data)
   //console.log(data);
   //console.log(showmystate+'hdhd')

   return
  }
  catch(err){
   // alert(err)
   alert('Something went wrong!\nSeems you are Offline.\n'+err)
   }
    
}

  export const getCatSel=async(setShowmystate,cat,wh='')=>{
    try{
      const response= await api.get('/getcat',{
          params: {
            cat,wh
          }
        });
        const {data} = response;
        setShowmystate(data)
        console.log('In getcat')
        console.log(data);
        return
    }
    catch(err){
      // alert(err)
      alert('Something went wrong!\nSeems you are Offline.\n'+err)
    }
    
  }

  export const getCatSelgrp = async(cat,wh='')=>{
    try{
      const response= await api.get('/getcat',{
          params: {
            cat,wh
          }
        });
        const {data} = response;
        //const obj ={};obj[index]=data;
         //changeTextHandler(data,setShowmystate,mystate,index);
        //setShowmystate({...mystate,...obj})
        // console.log('In getcat Grp')
        // console.log(mystate);
        // console.log('Index: '+index);
        return data
    }
    catch(err){
      // alert(err)
      alert('Something went wrong!\nSeems you are Offline.\n'+err)
    }
    
  }
  export const getOptionSel = async(setShowmystate,opt)=>{
    try{
      const response= await api.get('/getopt',{
        params: {
          opt,
        }
      });
      const {data} = response;
      //setShowmystate(data);
      //console.log(showmystate+'hdhd')
      setShowmystate(data)
      console.log(data);
      //console.log(showmystate+'hdhd')
  
      return 
    }
    catch(err){
      // alert(err)
      alert('Something went wrong!\nSeems you are Offline.\n'+err)
    }
    
  }

export const RenderList = (valARR,fielddesc)=>{
    if(Array.isArray(valARR) && valARR.length !== 0){ 
        //alert(valARR)
        //const myrolesKeys = Object.keys(role);

      const renderListItems=  Object.keys(valARR).map(val =>{
          return (<MenuItem  key={valARR[val].id} value={valARR[val].id}>{valARR[val][fielddesc]}</MenuItem >)
      });
      return renderListItems;
    }
    
}

export const RenderList2 = (valARR,fielddesc)=>{
  if(Array.isArray(valARR) && valARR.length !== 0){ 
      //alert(valARR)
      //const myrolesKeys = Object.keys(role);

    const renderListItems=  Object.keys(valARR).map(val =>{
        return (<option  key={valARR[val].id} value={valARR[val].id}>{valARR[val][fielddesc]}</option >)
    });
    return renderListItems;
  }
  
}

export const RenderGrp = (valARR,fielddesc,setFormstate,formstate)=>{
  if(valARR.length !== 0){ 
      //alert(valARR)
      //const myrolesKeys = Object.keys(role);

    const renderListItems=  Object.keys(valARR).map(val =>{
        return (<><GridContainer key={valARR[val].id} >
                            <GridItem style={{marginTop: 5,fontWeight:'500'}} xs={12}  sm={12} md={2}>
                            
  
                              {`${valARR[val].fname} [${valARR[val].gender}]`}
                            </GridItem>
                            <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={2}>
                                
                                <FormGroup >
                                  <FormControl >
                                      {/* <InputLabel htmlFor={`contr_fee${valARR[val].id}`}>Membership Contribution Fee</InputLabel> */}
                                      {/* <CurrencyInput placeholder="0.00" type="text" /> */}
                                      <TextField  variant="outlined"  title="Membership Contribution Fee" type="text" InputProps={{inputComponent: CurrencyInput,}} value={formstate[`contr_fee${valARR[val].id}`] || valARR[val].contr_fee}
                                      onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,`contr_fee${valARR[val].id}`)}} id={`contr_fee${valARR[val].id}`} 
                                      name={`contr_fee${valARR[val].id}`} />
                                      
                                  </FormControl>
                                </FormGroup>
                            </GridItem>
                            <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={2}>
                                
                                <FormGroup >
                                  <FormControl variant="outlined" >
                                      {/* <InputLabel htmlFor={`contr_startdate${valARR[val].id}`}>Contribution Start Date</InputLabel> */}
                                      <OutlinedInput  title="Contribution Start Date" type="date" value={formstate[`contr_startdate${valARR[val].id}`]|| valARR[val].contr_startdate}
                                      onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,`contr_startdate${valARR[val].id}`)}} id={`contr_startdate${valARR[val].id}`} 
                                      name={`contr_startdate${valARR[val].id}`} />
                                      
                                  </FormControl>
                                </FormGroup>
                            </GridItem>
                            <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={2}>
                                
                                <FormGroup >
                                  <FormControl  >
                                      {/* <InputLabel htmlFor={`total_amt_contr${valARR[val].id}`}>Total Amount Contribution to Date</InputLabel> */}
                                      <TextField variant="outlined"  title="Total Amount Contribution to Date" InputProps={{inputComponent: CurrencyInput,}} type="text" value={formstate[`total_amt_contr${valARR[val].id}`] || valARR[val].total_amt_contr }
                                      onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,`total_amt_contr${valARR[val].id}`)}} id={`total_amt_contr${valARR[val].id}`} 
                                      name={`total_amt_contr${valARR[val].id}`} />
                                      
                                  </FormControl>
                                </FormGroup>
                            </GridItem>
                            <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={2}>
                                
                                <FormGroup >
                                  <FormControl >
                                      {/* <InputLabel htmlFor={`expe_amt_contr${valARR[val].id}`}>Expected Contribution Amount to date</InputLabel> */}
                                      <TextField variant="outlined" InputProps={{inputComponent: CurrencyInput,shrink: true,}} title="Expected Contribution Amount to date" type="text" value={formstate[`expe_amt_contr${valARR[val].id}`] || valARR[val].expe_amt_contr}
                                      onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,`expe_amt_contr${valARR[val].id}`)}} id={`expe_amt_contr${valARR[val].id}`} 
                                      name={`expe_amt_contr${valARR[val].id}`} />
                                      
                                  </FormControl>
                                </FormGroup>
                            </GridItem>
                            <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={2}>
                                
                                <FormGroup >
                                  <FormControl variant="outlined" >
                                      {/* <InputLabel htmlFor={`contr_remark${valARR[val].id}`}>Remark</InputLabel> */}
                                      <TextField variant="outlined"   label="Remark"  value={formstate[`contr_remark${valARR[val].id}`]  || valARR[val].contr_remark}
                                      onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,`contr_remark${valARR[val].id}`)}} id={`contr_remark${valARR[val].id}`} 
                                      name={`contr_remark${valARR[val].id}`} type="text" />
                                      
                                  </FormControl>
                                </FormGroup>
                            </GridItem>
        </GridContainer>
        <MenuItem  key={valARR[val].id} value={valARR[val].id}>{valARR[val][fielddesc]}</MenuItem ></>)
    });
    return renderListItems;
  }
  
}
export const RenderGrpL = (valARR,fielddesc,setFormstate,formstate)=>{
  if(valARR.length !== 0){ 
      //alert(valARR)
      //const myrolesKeys = Object.keys(role);

    const renderListItems=  Object.keys(valARR).map(val =>{
        return (<><GridContainer key={valARR[val].id} >
                            <GridItem style={{marginTop: 5,fontWeight:'500'}} xs={12}  sm={12} md={2}>
                            
  
                              {`${valARR[val].fname} [${valARR[val].gender}]`}
                            </GridItem>
                            <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={2}>
                                
                                <FormGroup >
                                  <FormControl >
                                      {/* <InputLabel htmlFor={`contr_fee${valARR[val].id}`}>Membership Contribution Fee</InputLabel> */}
                                      {/* <CurrencyInput placeholder="0.00" type="text" /> */}
                                      <TextField  variant="outlined"  title="Amount of Loan per Person" type="text" InputProps={{inputComponent: CurrencyInput,}} value={formstate[`amtL${valARR[val].id}`] || valARR[val].amtL}
                                      onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,`amtL${valARR[val].id}`)}} id={`amtL${valARR[val].id}`} 
                                      name={`amtL${valARR[val].id}`} />
                                      
                                  </FormControl>
                                </FormGroup>
                            </GridItem>
                            <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={2}>
                                
                                <FormGroup >
                                   <FormControl >
                                      {/* <InputLabel htmlFor={`contr_fee${valARR[val].id}`}>Membership Contribution Fee</InputLabel> */}
                                      {/* <CurrencyInput placeholder="0.00" type="text" /> */}
                                      <TextField  variant="outlined"  title="Principal" type="text" InputProps={{inputComponent: CurrencyInput,}} value={formstate[`principal${valARR[val].id}`] || valARR[val].principal}
                                      onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,`principal${valARR[val].id}`)}} id={`principal${valARR[val].id}`} 
                                      name={`principal${valARR[val].id}`} />
                                      
                                  </FormControl>
                                </FormGroup>
                            </GridItem>
                            <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={2}>
                                
                                <FormGroup >
                                  <FormControl  >
                                      {/* <InputLabel htmlFor={`total_amt_contr${valARR[val].id}`}>Total Amount Contribution to Date</InputLabel> */}
                                      <TextField variant="outlined"  title="Interest" InputProps={{inputComponent: CurrencyInput,}} type="text" value={formstate[`intL${valARR[val].id}`] || valARR[val].intL }
                                      onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,`intL${valARR[val].id}`)}} id={`intL${valARR[val].id}`} 
                                      name={`intL${valARR[val].id}`} />
                                      
                                  </FormControl>
                                </FormGroup>
                            </GridItem>
                            <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={2}>
                                
                                <FormGroup >
                                  <FormControl >
                                      {/* <InputLabel htmlFor={`expe_amt_contr${valARR[val].id}`}>Expected Contribution Amount to date</InputLabel> */}
                                      <TextField variant="outlined" InputProps={{inputComponent: CurrencyInput,shrink: true,}} title="Total Repayment" type="text" value={formstate[`totalL${valARR[val].id}`] || valARR[val].totalL}
                                      onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,`totalL${valARR[val].id}`)}} id={`totalL${valARR[val].id}`} 
                                      name={`totalL${valARR[val].id}`} />
                                      
                                  </FormControl>
                                </FormGroup>
                            </GridItem>
                            <GridItem style={{marginTop: 5}} xs={12}  sm={12} md={2}>
                                
                                <FormGroup >
                                  <FormControl variant="outlined" >
                                      {/* <InputLabel htmlFor={`contr_remark${valARR[val].id}`}>Remark</InputLabel> */}
                                      <TextField variant="outlined"   label="Remark"  value={formstate[`remark${valARR[val].id}`]  || valARR[val].contr_remark}
                                      onChange={(e)=>{changeTextHandler(e.target.value,setFormstate,formstate,`remark${valARR[val].id}`)}} id={`remark${valARR[val].id}`} 
                                      name={`remark${valARR[val].id}`} type="text" />
                                      
                                  </FormControl>
                                </FormGroup>
                            </GridItem>
        </GridContainer>
        <MenuItem  key={valARR[val].id} value={valARR[val].id}>{valARR[val][fielddesc]}</MenuItem ></>)
    });
    return renderListItems;
  }
  
}

export const sendToServer=async(val,myurl,tab,setSsuccMessage,setSerrorMessage)=>{
    
    val.table = tab;
    console.log(val)
    const formData = new FormData();
        for (let key in val) {
            formData.append(key, val[key])
        }
      //console.log()
    let response
    try {
      response= await api.post(myurl,formData);
      const {data}=response;
      if(data=='1'){
        setSsuccMessage("Record Successfully Added!!");
      }else if(data=='2'){
        setSsuccMessage("Record Successfully Editted!!");
      }else{
        setSerrorMessage(`${data}` );
      }
      return data;
    }
    catch(err){
      // alert(err)
      alert('Something went wrong!\nSeems you are Offline.\n'+err)
      return
    }
}
export const changeTextHandler= (value,myfn,myval,elem)=>{
    let obj={};
    obj[elem]=value;
    if(elem === 'sregistered' && value==='0'){ obj['certificate_no']='';}
    console.log('myval');
    console.log(myval);
    return myfn({...myval,...obj});
}

export const inputCheck =(formstate,mystate,mytext)=>{
    const phoneno = /^\d{11}$/;
    const email =/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    // console.log('telll');
    const valelem =formstate[mystate];
    //if(mystate=='sregistered'){console.log(mytext+'dd');}
    switch (mytext){
        case 'text': return valelem && valelem.trim() !==''?true: false;
        case 'tel': return valelem.match(phoneno) ? true: false;
        case 'email': return valelem.match(email) ? true: false;
        default: return true;
    }
}

export const getDATA = async(myurl,val,chk='',wh='') =>{
    try{
        const response= await api.get(`${myurl}?tab=${val}&chk=${chk}&wh=${wh}&webview=1`);
        const {data} = response;
        const renderListItems=  Object.keys(data).map(val =>{
            return {...data[val][0]};
        });
        //console.log(data);
        
        
        console.log(renderListItems);
        return renderListItems;
    }
    catch(err){
      // alert(err)
      alert('Something went wrong!\nSeems you are Offline.\n'+err)
    }
  
} 

export const validate=(formstate,input_compul_array,input_type,setSerrorMessage,setError)=>{
  let isFormValid = true;
  // isFormValid = isFormValid && inputCheck(sgname,'text') && 
  //   inputCheck(sgrpType,'text') && inputCheck(ssuppType,'text') &&  inputCheck(sstate,'text') &&
  //   inputCheck(smfi,'text') && inputCheck(scontr_cycle,'text') &&  inputCheck(smin_contr,'text'); 
 
  let objError={};let mytruth='';if(!formstate['sregistered']){formstate['sregistered']='0';}//console.log('0');
   // obj[elem]=value;
  const mytruthValue = input_compul_array.map((key,index) =>{
                
                if(formstate[key]){
                  // console.log(`Keys : ${key}`)
                  // console.log(inputCheck(key,input_type[index]))
                  mytruth =  inputCheck(formstate,key,input_type[index]);
                  if(!mytruth){objError[key]='1'; }
                  isFormValid= isFormValid && mytruth;
                  //console.log(input_type[index])
                }else { isFormValid= false; objError[key]='1';}
                
                return isFormValid
      } )  
      if(formstate['sregistered']==='1' && formstate['certificate_no'].trim() ==='' ){ objError['certificate_no']='1';isFormValid= false;}
    
  if(isFormValid !== true){
    setSerrorMessage('Please Fill the Fields ');setError({...objError}); console.log(objError);
  }else{
    setSerrorMessage('');
  }
  console.log(`Truth value : ${mytruthValue} && isFormValid: ${isFormValid}`)
  return isFormValid

}