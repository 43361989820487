import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


// const dataSet1 = [
//     {
//         name: "Johson",
//         amount: 30000,
//         sex: 'M',
//         is_married: true
//     },
//     {
//         name: "Monika",
//         amount: 355000,
//         sex: 'F',
//         is_married: false
//     },
//     {
//         name: "John",
//         amount: 250000,
//         sex: 'M',
//         is_married: false
//     },
//     {
//         name: "Josef",
//         amount: 450500,
//         sex: 'M',
//         is_married: true
//     }
// ];

// var dataSet2 = [
//     {
//         name: "Johnson",
//         total: 25,
//         remainig: 16
//     },
//     {
//         name: "Josef",
//         total: 25,
//         remainig: 7
//     }
// ];

// class Download extends React.Component {
const Download = props =>{
    // render() {
        
        return (
            <ExcelFile element={<button style={{marginLeft:'5px'}} className="btn btn-sm btn-outline-success"><i className="fa fa-file-excel-o"></i> EXCEL</button>} filename={props.filename}>
                <ExcelSheet data={props.rows} name={props.filename}>
                    {props.headCells.map((col,index)=>{
                        return (
                          <ExcelColumn key={index} label={col['Header']} value={col['accessor']} />)
                        })
                    }
                    
                    
                </ExcelSheet>
                {/* <ExcelSheet data={dataSet2} name="Leaves">
                    <ExcelColumn label="Name" value="name"/>
                    <ExcelColumn label="Total Leaves" value="total"/>
                    <ExcelColumn label="Remaining Leaves" value="remaining"/>
                </ExcelSheet> */}
            </ExcelFile>
        );
    // }
}

export default Download;